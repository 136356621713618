<template>
  <div class="container">
    <div class="putitle">
      <span
        :class="
          datas.position == 1
            ? 'left'
            : datas.position == 3
            ? 'right'
            : ''
        "
        >{{ $i18n.locale == 'en' ? datas.titleen : datas.title }}</span
      >
    </div>
    <div
      class="bigItem"
      v-for="(item, i) in datas.item"
      :key="i"
      @click="golink(item.url)"
    >
      <div class="imgbox">
        <q-img :src="item.picture" alt="" :ratio="7 / 3" />
        <div class="btxt" v-if="item.title && item.contents">
          <h5>{{ item.title }}</h5>
          <p>{{ item.contents }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { golink } from '../index'
export default {
  name: 'bigImg',
  props: {
    datas: {
      type: Object
    }
  },
  setup (props) {
    return {
      golink
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin: 50px 0;
  .bigItem {
    width: 100%;
    display: flex;
    &:nth-child(even) {
      justify-content: flex-end;
      .imgbox {
        .btxt {
          text-align: left;
        }
      }
    }
    .imgbox {
      width: 75%;
      position: relative;
      margin-bottom: 30px;
      cursor: pointer;
      .btxt {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 50px 60px;
        color: #fff;
        font-size: 16px;
        text-align: right;
        text-shadow: 1px 1px 2px #000;
        background-image: linear-gradient(
          180deg,
          transparent,
          rgba(0, 0, 0, 0.6)
        );
        h5 {
          font-size: 36px;
          font-weight: bold;
          margin-bottom: 5px;
        }
        p {
          font-size: 16px;
          color: #fff;
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
